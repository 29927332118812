import React from "react"
import { Link } from "gatsby"

const PostCard = props => (
  <article
    className={`post-card ${props.count % 3 === 0 && `post-card-large`} ${
      props.postClass
    } ${props.node.frontmatter.thumbnail ? `with-image` : `no-image`}`}
    style={
      props.node.frontmatter.thumbnail && {
        backgroundImage: `url(${
          props.node.frontmatter.thumbnail.childImageSharp.gatsbyImageData.images.fallback.src
        })`,
      }
    }
  >
    <Link to={props.node.fields.slug} className="post-card-link">
      <div className="post-card-content">
        <h3 className="post-card-title">
          {props.node.frontmatter.title.toUpperCase() || props.node.fields.slug}
        </h3>
      </div>
    </Link>
    {/* <p>{props.node.frontmatter.title.toUpperCase()}</p> */}
  </article>
)

export default PostCard
