import React from "react"
import { graphql, StaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PostCard from "../components/postCard"
import PortfolioMenu from "../components/portfolio-menu"

import "../utils/normalize.css"
import "../utils/css/screen.css"
import { doc } from "prettier"

const BlogIndex = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges
  let postCounter = 0

  function handleEnter() {
    const tagline = document.getElementById('tagline');
    tagline.innerHTML = "new media artist";
  }

  function handleLeave() {
    const tagline = document.getElementById('tagline');
    tagline.innerHTML = "creative technologist";
  }

  return (
    <Layout title={siteTitle}>
      <SEO title="LC" keywords={[`blog`, `ITP`, `NYU`, `creative technologist`, `gatsby`, `arduino`]} />
      {data.site.siteMetadata.description && (
        // <header className="page-head" onMouseEnter={handleEnter} onMouseLeave={handleLeave}>
        <header className="page-head">
          {/* <h2 className="page-head-title" id="tagline">
            {data.site.siteMetadata.description}
          </h2> */}
          <h2 className="page-head-title default" id="tagline" >
            {data.site.siteMetadata.description}
          </h2>
          <h2 className="page-head-title secondary" id="tagline" >
            new media artist
          </h2>
        </header>
      )}
      <div className="post-feed">
        {posts.map(({ node }) => {
          postCounter++
          return (
            <PostCard
              key={node.fields.slug}
              count={postCounter}
              node={node}
              postClass={`post`}
            />
          )
        })}
      </div>
    </Layout>
  )
}

const indexQuery = graphql`{
  site {
    siteMetadata {
      title
      description
    }
  }
  allMarkdownRemark(
    sort: {fields: [frontmatter___date], order: DESC}
    filter: {frontmatter: {tags: {in: ["showcase"]}}}
  ) {
    edges {
      node {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          thumbnail {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
}
`

const Index = props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <BlogIndex location={props.location} props data={data} {...props} />
    )}
  />
)

export default Index
